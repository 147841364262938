import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/CloseRounded';
import FoxiTeacher from '../../assets/images/characters/foxi-teacher.png';
import BG4 from '../../assets/images/home-elements/not-tap-tap/bg-4.jpg';
import Foxi from '../../assets/images/home-elements/not-tap-tap/foxi-teacher-2.png';

const PopupContainer = styled(Box)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '70vw',
  maxWidth: '360px',
  background: 'repeating-radial-gradient(#6301cbbd 0%, #560ca0cc 100px)',
  backgroundSize: '100vw',
  backgroundPosition: '-40px 300px',
  borderRadius: '40px',
  border: '7px solid #FFA500',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 999,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.5)',
  transition: 'all 0.3s',
  willChange: 'transform, opacity',
  backdropFilter: 'blur(10px)'
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: '10px',
  top: '10px',
  color: 'white'
});

const PrizesContainer = styled(Box)({
  width: '90%',
  height: '30vh',
  background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2))',
  borderRadius: '15px',
  padding: '10px',
  marginBottom: '10px',
  border: '2px solid rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  overflowY: 'scroll',
});

const PrizeItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0px',
  '&:last-child': {
    marginBottom: 0,
  },
});

const watchTutorialButton = {
  width: '90%',
  height: '50px',
  fontSize: '18px',
  fontFamily: 'Sora, sans-serif',
  fontWeight: 'bold',
  filter: 'hue-rotate(300deg)',
  margin: '0 auto',
  mt: '10px'
};

const closeButton = {
  width: '90%',
  height: '50px',
  fontSize: '18px',
  fontFamily: 'Sora, sans-serif',
  fontWeight: 'bold',
  filter: 'hue-rotate(75deg)',
  margin: '0 auto',
  mt: '10px'
};

const CustomCenterPopup = memo(({ 
  open, 
  onClose, 
  StyledButton, 
  link = null, 
  handlerButton = null,
  buttonLabel = "Okay",
  linkButtonLabel = null, 
  title = null, 
  subTitle = null, 
  popupImg = null,
  imgStyle = {},
  prizes = null,
  prizeStyle = {},
  prizeNotes = {},
  note
}) => {

  const onWatchVideo = () => {
    window.open(link, '_blank');
  };

  return (
    <PopupContainer sx={{ opacity: open ? 1 : 0, transform: open ? 'translate(-50%, -50%)' : 'translate(-160%, 50%)' }}>
      <Box
        component="img"
        src={popupImg || Foxi}
        alt="Foxi"
        sx={{ 
          width: '115%',
          position: 'relative',
          top: '-20px',
          borderRadius: '33px 33px 60px 60px',
          height: '200px',
          ...imgStyle
        }}
      />

      <Typography
        sx={{
          color: '#FF69B4',
          fontSize: '22px',
          fontWeight: 'bolder',
          fontFamily: 'sora, sans-serif',
          textAlign: 'center'
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          color: 'white',
          fontSize: '15px',
          fontFamily: 'sora, sans-serif',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '10px',
          width: '80%'
        }}
      >
        {subTitle}
      </Typography>

      {prizes && (
        <PrizesContainer>
          <Typography 
             sx={{
              color: '#FFD700',
              fontSize: '15px',
              fontWeight: 'bold',
              fontFamily: 'sora, sans-serif',
              minWidth: '30px'
            }}
          >
           To be eligible:
          </Typography>
          {Object.entries(prizes).map(([key, value]) => (
            <PrizeItem key={key}>
              <Typography
                sx={{
                  color: '#FFD700',
                  fontSize: '20px',
                  fontFamily: 'Sora, sans-serif',
                  fontWeight: 'bolder',
                  minWidth: '25px'
                }}
              >
                {key}.
              </Typography>
              <Box display="flex" flexDirection="column" sx={{ ...prizeStyle }}>
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'sora, sans-serif',
                    flexGrow: 1
                  }}
                >
                  {value}
                </Typography>
                {prizeNotes[key] && (
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '13px',
                      fontFamily: 'Sora, sans-serif',
                      flexGrow: 1,
                    }}
                  >
                    {prizeNotes[key]}
                  </Typography>
                )}
              </Box>
            </PrizeItem>
          ))}
        </PrizesContainer>
      )}

      {link && (
        <StyledButton onClick={onWatchVideo} sx={watchTutorialButton}>
          {linkButtonLabel}
        </StyledButton>
      )}

      {handlerButton && (
        <StyledButton onClick={handlerButton?.onClick} sx={watchTutorialButton}>
          {handlerButton?.label}
        </StyledButton>
      )}

      <StyledButton onClick={onClose} sx={closeButton}>
        {buttonLabel}
      </StyledButton>

     {note && (
      <Typography
        sx={{
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: '14px',
          fontFamily: 'sora, sans-serif',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          marginTop: '8px'
        }}
      >
        <Box
          component="img"
          src="https://img.icons8.com/3d-fluency/188/alarm-clock--v2.png"
          alt="clock"
          sx={{ width: '20px', height: '20px' }}
        />
        {note}
      </Typography>
      )}
      </PopupContainer>
  );
});

CustomCenterPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  StyledButton: PropTypes.elementType.isRequired,
  link: PropTypes.string,
  handlerButton: PropTypes.object,
  buttonLabel: PropTypes.string,
  linkButtonLabel: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  popupImg: PropTypes.string,
  imgStyle: PropTypes.object,
  prizes: PropTypes.object,
  prizeStyle: PropTypes.object,
  prizeNotes: PropTypes.object,
  note: PropTypes.string
};

CustomCenterPopup.displayName = 'CustomCenterPopup';

export default CustomCenterPopup;
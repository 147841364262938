// src/components/MusicButton.jsx
import React from 'react';
import { Box } from '@mui/material';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Ban } from 'lucide-react';


const MusicButton = ({ isPlaying, onToggle, right = '20px', top = '30%' }) => {
  return (
    <Box
      onClick={onToggle}
      sx={{
        position: 'absolute',
        right,
        top,
        transform: 'translateY(-50%)',
        width: '57px',
        height: '57px',
        borderRadius: '20px',
        minWidth: 'unset',
        padding: 0,
        zIndex: 99,
        background: '#1b1d1d82',
        border: '2px solid #fffefc',
        boxShadow: '0 0 15px rgba(255, 255, 255, 0.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        // animation: customAnimation,
        '&:hover': {
          background: 'linear-gradient(45deg, #ff3366, #ff6b6b)',
          transform: 'translateY(-50%) scale(1.1)',
          boxShadow: '0 0 25px rgba(255, 255, 255, 0.5)',
        }
      }}
    >
       <DotLottieReact
          src="https://lottie.host/fd62ffb1-1e95-4738-9cfc-133640373407/8C72dvhW1l.lottie"
          loop
          autoplay
          style={{ width: '75px', height: '67px', scale: 1.2}}
          speed={isPlaying ? 1.85 : 0.1}
      />
       {!isPlaying && (
         <Ban
           size={20}
           color="red"
           strokeWidth={3.25}
           style={{ position: 'absolute', right: '-4%', top: '4%' }}
         />
       )}
    </Box>
  );
};

export default MusicButton;
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/system';

const ListContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100vw',
  height: 'auto',
  backgroundColor: '#00000086',
  alignItems: 'center',
  paddingBottom: '20vh',
  backdropFilter: 'blur(5px)',
  borderRadius: '13% 13% 0 0'
});

const FriendCard = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '90%',
  height: '7.5vh',
  backgroundColor: '#0b081ed6',
  border: '1px solid #BEB7FF',
  borderRadius: '20px',
  margin: '2px 0',
  padding: '5px 0',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.02)'
  }
});

const ScrollableContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '35vh',
  overflow: 'auto',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    width: '5px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#BEB7FF',
    borderRadius: '10px'
  }
});

const FriendInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const ProfileAvatar = styled(Avatar)({
  width: 48,
  height: 48,
  margin: '0 8px',
  border: '3px solid white',
  borderRadius: '50%'
});

const FriendsList = memo(({ friends = [], friendsCount = 0, ENDPOINT }) => {
  if (friendsCount === 0) {
    return (
      <ListContainer>
        <Typography 
          variant="h5" 
          sx={{
            fontFamily: 'Poppins-Bold, sans-serif',
            fontWeight: 'bold',
            color: 'white',
            mt: 5,
            mb: 15,
            px: 2,
            py: 1,
            background: 'rgba(0,0,0,0.65)',
            borderRadius: '15px',
            zIndex: 1
          }}
        >
          No any friends!
        </Typography>
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      <Grid item xs={12} sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        m: '16px auto',
        maxHeight: '10vh' 
      }}>
        <Typography variant="h6" sx={{ 
          fontFamily: 'Poppins-Bold, sans-serif',
          fontWeight: 'bold',
          color: 'white',
          zIndex: 1
        }}>
          {`Friends list (${friendsCount})`}
        </Typography>
      </Grid>

      <ScrollableContainer>
        {friends.slice(0, 25).map((friend, index) => (
          <FriendCard key={friend.userId || index}>
            <ProfileAvatar
              alt={friend.firstname}
              src={`${process.env.REACT_APP_AVATAR_URL}/${friend.userId}.jpg`}
            />
            <FriendInfo>
              <Typography sx={{ 
                fontFamily: 'Sora, sans-serif',
                fontWeight: 'bold',
                color: 'white',
                mt: 2
              }}>
                {friend.firstName}
              </Typography>
              <Typography variant="body2" sx={{ 
                fontFamily: 'Sora, sans-serif',
                color: 'white',
                mb: 2,
                textWrap: 'nowrap'
              }}>
                {friend.stats.league || 'No League'}
              </Typography>
            </FriendInfo>
            <Typography sx={{ 
              fontFamily: 'Sora, sans-serif',
              fontWeight: 'bold',
              color: 'white',
              ml: 'auto',
              mr: 2
            }}>
              {friend.stats.points > 0 ? `${(friend.stats.points * 0.003).toFixed(2)}` : '0'}
            </Typography>
          </FriendCard>
        ))}
      </ScrollableContainer>
    </ListContainer>
  );
});

FriendsList.propTypes = {
  friends: PropTypes.array,
  friendsCount: PropTypes.number,
  ENDPOINT: PropTypes.string.isRequired
};

FriendsList.displayName = 'FriendsList';

export default FriendsList;
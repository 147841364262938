import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';

// Import components
import FriendsList from './Referral/FriendsList';
import ShareLink from './Referral/ShareLink';
import ButtonTabs from './Tabs/Tabs';
import GradientBoxShadow from './GradientBoxShadow/GradientBoxShadow';

// Import assets
import ReferralBackground from '../assets/images/referral-bg.png';

// Styled Components
const PageContainer = styled(Box)({
  width: '100vw',
  height: '110vh',
  minHeight: '100vh',
  display: 'flex',
  backgroundImage: `url(${ReferralBackground})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  willChange: 'transform' // Optimize background performance
});

const ContentGrid = styled(Grid)({
  height: '100vh',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignContent: 'space-between',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingTop: '35vh'
});

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins-Bold, sans-serif',
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'white',
  zIndex: 1
}));

const Subtitle = styled(Typography)({
  fontFamily: 'Sora, sans-serif',
  textAlign: 'center',
  color: 'white',
  marginBottom: '16px',
  zIndex: 1
});

// Theme configuration
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#8e44ad' },
    background: {
      default: '#1e0533',
      paper: '#3c1f5c'
    }
  },
  typography: {
    fontFamily: 'Arial, sans-serif'
  }
});

// Memoized gradient box style
const gradientStyle = {
  top: '65vh',
  left: '45vw',
  boxShadow: 'rgb(255 135 27 / 93%) -14vh -17vh 20vh 11vh, #df32ffcf -28vh 20vh 20vh 15vh, #ff0034c4 15vh -29vh 20vh 14vh, rgba(255, 0, 159, 0.9) -16vh 11vh 23vh 4.44vh'
};

const InviteFriends = memo(({ userData, userProfileInfo, telApp, ENDPOINT }) => {
  const [userId, setUserId] = useState(null);
  const [inviteLink, setInviteLink] = useState('');
  const [shareMessageLink, setShareMessageLink] = useState('');

  // Memoized link creation functions
  const createInviteLink = useCallback((uid) => {
    return `https://t.me/FoxiTips_bot/launch?startapp=${uid}`;
  }, []);

  const createShareMessageLink = useCallback((text) => {
    const encodedText = encodeURIComponent(text);
    return `https://t.me/share/url?url=${encodedText}`;
  }, []);

  // Handle link updates
  useEffect(() => {
    if (!userId) return;

    const newInviteLink = createInviteLink(userId);
    setInviteLink(newInviteLink);

    const message = `Join Foxi mini-app now! 🎉
Get daily budgets and enjoy your free tipping.\n\nClick the link below to get started.\n${newInviteLink}`;
    setShareMessageLink(createShareMessageLink(message));
  }, [userId, createInviteLink, createShareMessageLink]);

  // Set user ID
  useEffect(() => {
    if (userData?.id) {
      setUserId(userData.id);
    }
  }, [userData]);

  // Handle clipboard
  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(inviteLink)
      .then(() => telApp.showAlert('Invite link copied!'))
      .catch(err => console.error('Failed to copy:', err));
  }, [inviteLink, telApp]);

  return (
    <ThemeProvider theme={theme}>
      <PageContainer>
        <ContentGrid container spacing={0} justifyContent="center">
          <Title variant="h4" component="h1">
            Invite Friends!
          </Title>
          
          <Subtitle variant="subtitle1" component="h2">
            Get 5% of your friends points
          </Subtitle>

          <GradientBoxShadow customStyle={gradientStyle} />

          <FriendsList 
            friends={userProfileInfo.referral.activeReferrals}
            friendsCount={userProfileInfo.referral.totalReferrals}
            userId={userId}
            telApp={telApp}
            ENDPOINT={ENDPOINT}
          />

         <ShareLink 
              inviteLink={inviteLink}
              shareMessageLink={shareMessageLink}
              copyToClipboard={handleCopyToClipboard}
              telApp={telApp}
            />
        </ContentGrid>
      </PageContainer>
          
      <ButtonTabs activeTab={3} blurEffect={true} />
    </ThemeProvider>
  );
});

InviteFriends.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number
  }),
  userProfileInfo: PropTypes.shape({
    referralsList: PropTypes.array,
    referrals: PropTypes.number
  }).isRequired,
  telApp: PropTypes.object.isRequired,
  ENDPOINT: PropTypes.string.isRequired
};

InviteFriends.displayName = 'InviteFriends';

export default InviteFriends;